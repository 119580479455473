import { mdiClose, mdiDeveloperBoard, mdiPencilOutline, mdiPencilPlus, mdiTrashCanOutline } from "@mdi/js";
import commonDataModule from "@/store/modules/commonDataModule";
import spinnerModule from "@/store/modules/spinnerModule";
import { Guid } from "guid-typescript";
import { ApiSistemaPagos, AxiosHttpClient, ApiErrorResponse } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";
import { KioskoMacAddress } from "apd.sistemapagos.models";
import { formatISO } from "date-fns";
import Vue from "vue";
import snackbarModule from "@/store/modules/snackbarModule";

export default Vue.extend({
  name: "MacAdress",

  data() {
    return {
      Icons: {
        Mac: mdiDeveloperBoard,
        Close: mdiClose,
        Edit: mdiPencilOutline,
        Delete: mdiTrashCanOutline,
        Add: mdiPencilPlus,
      } as Record<string, string>,
      dialog: false,
      headers: [
        { text: "Cajero", value: "idKiosko", sortable: false, align: "start" },
        { text: "Mac Address", value: "macAddress", sortable: false, align: "start" },
        { text: "Fecha", value: "updated", sortable: true, align: "start" },
        { text: "", value: "id", sortable: false, align: "start" },
        { text: "", value: "id", sortable: false, align: "start" },
      ],
      selectCajero: "",
      macAddressList: [] as KioskoMacAddress[],
      loading: false,
      selectedMac: {} as KioskoMacAddress,
      macAddress: "",
      funcion: "",
      titulo: "",
      dialogConfirmacion: false,
      prefijo: "" as string | undefined,
      pinpad: "" as string | undefined,
    };
  },
  computed: {
    cajerosList() {
      return commonDataModule.Cajeros.filter((c) => !c.nombre.includes("CajaGeneral")).sort((a, b) =>
        a.nombrePublico > b.nombrePublico ? 1 : -1,
      );
    },
    cajeroToString() {
      return (value: string): string | undefined => {
        return commonDataModule.Cajeros.find((c) => c.id == value)?.nombrePublico;
      };
    },
    disableBtn() {
      if (this.selectCajero != undefined && this.selectCajero != "") {
        return false;
      } else {
        return true;
      }
    },
    disableEdit() {
      if (this.macAddress != undefined && this.macAddress != "") {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    async load() {
      spinnerModule.Show();
      await commonDataModule.LoadCajeros();
      this.dialog = true;
      spinnerModule.Hide();
    },
    async change() {
      this.loading = true;
      const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
        getUrl(ApiList.SistemaPagos, this.$store.getters.appMode),
      );
      axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

      this.prefijo = this.cajerosList.find((c) => c.id == this.selectCajero)?.prefijo;
      this.pinpad = this.cajerosList.find((c) => c.id == this.selectCajero)?.numeroSeriePinPad;

      await apiSP
        .GetKioskoMacAddress(this.selectCajero)
        .then((e) => {
          const list = e as KioskoMacAddress[];
          this.macAddressList = list.sort((a, b) => (a.updated < b.updated ? 1 : -1));
        })
        .catch(() => {
          snackbarModule.Show("No se encontraron mac address para este cajero.");
        });
      this.funcion = "";
      this.loading = false;
    },
    async mac() {
      this.loading = true;
      const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
        getUrl(ApiList.SistemaPagos, this.$store.getters.appMode),
      );
      axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);
      if (this.funcion == "crear") {
        await apiSP
          .CreateKioskoMac({
            id: Guid.create().toString(),
            idKiosko: this.selectCajero,
            macAddress: this.macAddress.toUpperCase(),
            created: formatISO(Date.now()),
            updated: formatISO(Date.now()),
          })
          .then(() => {
            snackbarModule.ShowSuccess("Mac address creada");
          })
          .catch((err) => {
            const x = err as ApiErrorResponse;
            x.apiError.mensajeUsuario;
            snackbarModule.Show(x.apiError.mensajeUsuario);
          });
      }
      if (this.funcion == "editar") {
        await apiSP
          .UpdateKioskoMac({
            id: this.selectedMac.id,
            idKiosko: this.selectCajero,
            macAddress: this.macAddress.toUpperCase(),
            created: this.selectedMac.created,
            updated: formatISO(Date.now()),
          })
          .then(() => {
            snackbarModule.ShowSuccess("Mac address actualizada");
          })
          .catch((err) => {
            const x = err as ApiErrorResponse;
            x.apiError.mensajeUsuario;

            snackbarModule.Show(x.apiError.mensajeUsuario);
          });
      }
      if (this.funcion == "eliminar") {
        await apiSP
          .DeleteKioskoMac(this.selectedMac.id)
          .then(() => {
            snackbarModule.ShowSuccess("Mac address eliminada");
          })
          .catch((err) => {
            const x = err as ApiErrorResponse;
            x.apiError.mensajeUsuario;
            snackbarModule.Show(x.apiError.mensajeUsuario);
          });
      }

      await apiSP
        .GetKioskoMacAddress(this.selectCajero)
        .then((e) => {
          const list = e as KioskoMacAddress[];
          this.macAddressList = list.sort((a, b) => (a.updated < b.updated ? 1 : -1));
        })
        .catch(() => {
          snackbarModule.Show("No se encontraron mac address para este cajero.");
        });
      this.dialogConfirmacion = false;
      this.loading = false;
    },
    cancelar() {
      this.selectedMac = {} as KioskoMacAddress;
      this.macAddress = "";
      this.funcion = "";
    },
    crear() {
      this.funcion = "crear";
      this.selectedMac = {} as KioskoMacAddress;
      this.titulo = "Crear mac address";
    },
    editar(mac: KioskoMacAddress) {
      this.selectedMac = mac;
      this.funcion = "editar";
      this.titulo = "Editar mac address";
    },
    eliminar(mac: KioskoMacAddress) {
      this.funcion = "eliminar";
      this.selectedMac = mac;
      this.titulo = "Eliminar mac address";
      this.dialogConfirmacion = true;
    },
  },
});
